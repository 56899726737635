import { useEffect, useState, MutableRefObject } from 'react';

type Options = {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
};

const useOnScreen = <T extends HTMLElement>(ref: MutableRefObject<T | null>, options: Options = {}): boolean => {
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;
            setIsInView(entry.isIntersecting);
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target);
            }
        };

        const observer = new IntersectionObserver(observerCallback, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);

    return isInView;
}

export default useOnScreen;
