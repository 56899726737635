import React, {useEffect, useRef} from 'react';
import SectionTitle from "./SectionTitle";

import project1 from "../images/project-1.png";
import project2 from "../images/project-2.png";
import project3 from "../images/project-3.png";
import project4 from "../images/project-4.png";
import project5 from "../images/project-5.png";
import project6 from "../images/project-6.png";

import '../projects.css';
import useOnScreen from "../hooks/useOnScreen";
import {Link} from "react-router-dom";

interface ProjectsSectionProps {
    // Define your prop types here (if any)
}

const ProjectsSection: React.FC<ProjectsSectionProps> = (props) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const ref4 = useRef<HTMLDivElement>(null);
    const ref5 = useRef<HTMLDivElement>(null);
    const ref6 = useRef<HTMLDivElement>(null);

    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);
    const isInView3 = useOnScreen(ref3);
    const isInView4 = useOnScreen(ref4);
    const isInView5 = useOnScreen(ref5);
    const isInView6 = useOnScreen(ref6);

    return (
        <div className="px-4 py-14 md:py-[120px]">
            <div className="max-w-7xl mx-auto">
                <SectionTitle title={'Projects'} />
                <div className="pt-10 md:pt-20 flex flex-col">
                    <div className="project-fade-in" ref={ref1}>
                        <a href="https://aijobsboard.net">
                            <img src={project1} alt="AI Jobs Board" />
                        </a>
                    </div>
                    <div className="flex flex-row flex-wrap sm:flex-nowrap justify-between mt-4 sm:mt-12">
                        <div
                            className="project-fade-in mb-4 sm:mb-0 sm:mr-2 lg:mr-8"
                            ref={ref2}
                        >
                            <a href="https://interviewmeai.com">
                                <img src={project2} alt="InterviewMe AI" />
                            </a>
                        </div>
                        <div
                            className="project-fade-in"
                            ref={ref3}
                        >
                            <a href="https://decrypt.co/30948/how-to-win-cryptocurrency-by-playing-flappy-bird">
                                <img src={project3} alt="Flappy Nano Game" />
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-12 project-fade-in" ref={ref4}>
                        <Link to={'/kickit'}>
                            <img src={project4} alt="Kickit App" />
                        </Link>
                    </div>
                    <div className="flex flex-row flex-wrap sm:flex-nowrap justify-between mt-4 sm:mt-12">
                        <div className="mb-4 sm:mb-0 sm:mr-2 lg:mr-8 project-fade-in" ref={ref5}>
                            <a href="https://asharmalik.github.io/Washington--Washington/">
                                <img src={project5} alt="Washington, Washington Game"/>
                            </a>
                        </div>
                        <div className="project-fade-in" ref={ref6}>
                            <a href="https://github.com/asharmalik/Dogecoin">
                                <img src={project6} alt="Dogecoin game" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectsSection;
