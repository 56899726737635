import React, {useState} from 'react';
import button from '../images/see-my-work-btn.png';
import buttonHover from '../images/see-my-work-btn-hover.png';
import meteor1 from '../images/meteor-1.png';
import meteor2 from '../images/meteor-2.png';
import meteor3 from '../images/meteor-3.png';
import meteor4 from '../images/meteor-4.png';
import bgImage from '../images/above-the-fold-bg.png'
import '../above-the-fold.css'
import mixpanel from "mixpanel-browser";

interface AboveTheFoldProps {
    // Define your prop types here (if any)
}

const AboveTheFold: React.FC<AboveTheFoldProps> = (props) => {
    const [isButtonHovered, setButtonHovered] = useState(false);  // State for button hover
    const handleButtonClick = () => {
        mixpanel.track('Click see my work');
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth' // smooth scrolling effect
        });
    }

    return (
        <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className="min-h-screen flex items-center justify-center bg-custom-gray bg-cover bg-no-repeat relative overflow-hidden z-30"
        >
            {/* Meteors */}
            <img src={meteor1} className="meteor meteor-1 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-2 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-3 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-4 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-5 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-6 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-7 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-8 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-9 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-10 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-11 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-12 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-13 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-14 z-10" alt="Meteor" />

            <div className="font-raleway text-3xl md:text-6xl font-medium leading-140 text-white z-20 mx-10 md:mx-20">
                <div className={"inline-block"}>
                    <p className="text-center mb-2 animated-text-1">Hello, I'm <div className="inline text-custom-blue font-bold">Ashar Malik.</div></p>
                </div>
                <div className="text-center">I'm a Full-Stack Software Engineer.</div>
                <div className="flex items-center justify-center"> {/* This parent div fills the screen and centers its children */}
                    <img
                        width={485}
                        height={305}
                        src={isButtonHovered ? buttonHover : button}  // Conditional rendering based on hover state
                        className="cursor-pointer"
                        alt="Button"
                        onMouseEnter={() => setButtonHovered(true)}  // Set hover state to true when mouse enters
                        onMouseLeave={() => setButtonHovered(false)}  // Set hover state to false when mouse leaves
                        onClick={handleButtonClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default AboveTheFold;
