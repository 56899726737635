import React, {useRef} from 'react';
import useOnScreen from "../hooks/useOnScreen";
import '../css/section-title.css'

interface SectionTitleProps {
    title: string;
    color?: string;
    animateDir?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, color, animateDir }) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);

    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);

    return (
        <div className="flex items-center flex-col">
            <div
                className={`fade-in-${animateDir} text-center font-raleway text-${color} font-bold text-3xl md:text-5xl`}
                ref={ref1}
            >
                {title}
            </div>
            <div ref={ref2} className={`fade-in-delayed-${animateDir} w-[68px] md:w-[96px] h-[4px] md:h-[6px] bg-${color} mt-2 md:mt-6`}></div>
        </div>
    );
}

SectionTitle.defaultProps = {
    color: "custom-gray",
    animateDir: "left",
};

export default SectionTitle;
