import React, {useEffect, useRef, useState} from 'react';
import useOnScreen from "../hooks/useOnScreen";

interface ProgressBarProps {
    numYears: number;
    totalYears: number;
    position: number;
    skill: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ numYears, totalYears, position, skill }) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const isInView1 = useOnScreen(ref1);
    const [fillWidth, setFillWidth] = useState(0); // Start with 0% width
    const fillPercentage = (numYears / totalYears) * 100;

    useEffect(() => {
        // Animate the progress bar after the component is mounted
        requestAnimationFrame(() => {
            setFillWidth(fillPercentage);
        });
    }, [fillPercentage]);

    return (
        <div ref={ref1} className="bg-progres-bar-bg flex items-center h-10 w-full font-raleway font-bold my-4">
            <div className="bg-[#06C2C9] text-white w-32 h-full flex items-center">
                <div className="m-auto">{skill}</div>
            </div>
            <div className="flex-grow relative h-full">
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#00A1A6',
                        transition: 'all 1000ms',
                        width: `${isInView1 ? fillWidth: 0}%`,
                        transitionDelay: `${position/20+0.25}s`
                    }}
                ></div>
            </div>
            <div className="pr-4 pl-10">{numYears} yrs</div>
        </div>
    );
}

export default ProgressBar;
