import React from 'react';
import './css/App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import KickitPage from "./pages/KickitPage";
import mixpanel from 'mixpanel-browser';

mixpanel.init('1f6dd5904e84610ccb2fb35b08eaabbb');

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" Component={HomePage}/>
          <Route path="/kickit" Component={KickitPage}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
