import React, {useEffect, useRef} from 'react';
import SectionTitle from "./SectionTitle";
import dell from "../images/dell.png";
import microsoft from "../images/microsoft.png";
import zenbusiness from "../images/zenbusiness.png";
import vectra from "../images/vectra.png";

import '../css/companies.css';
import useOnScreen from "../hooks/useOnScreen";

interface CompaniesSectionProps {
    // Define your prop types here (if any)
}

const CompaniesSection: React.FC<CompaniesSectionProps> = (props) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const ref4 = useRef<HTMLDivElement>(null);

    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);
    const isInView3 = useOnScreen(ref3);
    const isInView4 = useOnScreen(ref4);

    return (
        <div className="bg-light-gray px-4 py-14 md:py-[120px]">
            <div className="max-w-7xl mx-auto">
                <SectionTitle
                    title={'Companies'}
                    animateDir={'right'}
                />
                <div className="flex flex-row flex-wrap md:flex-nowrap justify-center md:justify-between mt-14 md:mt-28 w-full">
                    <div
                        className="image-fade-in-up mb-10 sm:mb-20 md:mb-0 md:pr-10 flex-grow mx-10 sm:mx-0"
                        ref={ref1}
                    >
                        <img className="m-auto w-56 sm:w-auto" src={microsoft} />
                    </div>
                    <div
                        className="image-fade-in-up mb-10 sm:mb-20 md:mb-0 md:pr-10 flex-grow mx-10 sm:mx-0"
                        ref={ref2}
                    >
                        <img className="m-auto w-56 sm:w-auto" src={dell} />
                    </div>
                    <div
                        className="image-fade-in-up mb-10 sm:mb-20 md:mb-0 md:pr-10 flex-grow mx-10 sm:mx-0"
                        ref={ref3}
                    >
                        <img className="m-auto w-56 sm:w-auto" src={zenbusiness} />
                    </div>
                    <div
                        className="image-fade-in-up flex-grow mx-10 sm:mx-0"
                        ref={ref4}
                    >
                        <img className="m-auto w-56 sm:w-auto" src={vectra} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompaniesSection;
