import React, {useRef} from 'react';
import SectionTitle from "./SectionTitle";

import aboutCube from '../images/about-cube.png'
import useOnScreen from "../hooks/useOnScreen";
import SpinningCube from "./SpinningCube";

interface AboutSectionProps {
    // Define your prop types here (if any)
}

const AboutSection: React.FC<AboutSectionProps> = (props) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);

    return (
        <div className="px-4 py-14 md:py-20 bg-light-gray ">
            <div>
                <SectionTitle title={'About'} />
            </div>
            <div className="flex max-w-7xl lg:justify-between m-auto flex-wrap md:flex-nowrap mt-10 justify-center">
                <div
                    ref={ref1}
                    className="h-full fade-in-right lg:w-1/2 mx-auto lg:px-10 sm:mx-0"
                >
                    <SpinningCube imageUrls={
                        [
                            '/cube/1.jpg',
                            '/cube/5.jpg',
                            '/cube/3.jpg',
                            '/cube/4.jpg',
                            '/cube/2.jpg',
                            '/cube/6.jpg',
                        ]
                    } />
                </div>

                <div
                    ref={ref2}
                    className="fade-in-left p-4 text-left"
                >
                    <div className="font-open-sans text-xl md:text-3xl font-semibold leading-140 text-custom-blue-2 mb-6">
                        Builder at heart
                    </div>
                    <div className="text-black font-open-sans text-md md:text-xl font-normal max-w-2xl">
                        I am a Software Engineer, well-versed in modern engineering practices. Building products is my passion.
                        <br/>
                        <br/>
                        <br/>
                        The primary goals that guide me are architecting complex systems with simplicity and a keen focus on user-centric design.
                        <br/>
                        <br/>
                        <br/>
                        I enjoy crafting beautiful experiences that are intuitive, clean and a joy to use. My interests include scalability, distributed systems, and artificial intelligence.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
