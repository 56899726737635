import React, {useRef} from 'react';
import SectionTitle from "./SectionTitle";
import ProgressBar from "./ProgressBar";
import useOnScreen from "../hooks/useOnScreen";

interface SkillsSectionProps {
    // Define your prop types here (if any)
}

const SkillsSection: React.FC<SkillsSectionProps> = (props) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const isInView1 = useOnScreen(ref1);

    return (
        <div className="px-4 max-w-7xl m-auto">
            <div className="flex items-center my-20 w-full flex-wrap md:flex-nowrap flex-col md:flex-row">
                <div className={'mx-10 mb-10 md:mb-0 md:min-w-[250px] xl:min-w-[400px]'}>
                    <SectionTitle
                        title={'Skills'}
                        animateDir={'right'}
                    />
                </div>
                <div
                    className="fade-in-left flex-grow w-full md:w-auto"
                    ref={ref1}
                >
                    <ProgressBar position={1} skill={'JavaScript'} numYears={8} totalYears={8}/>
                    <ProgressBar position={2} skill={'React'} numYears={7} totalYears={8}/>
                    <ProgressBar position={3} skill={'HTML'} numYears={8} totalYears={8}/>
                    <ProgressBar position={4} skill={'CSS'} numYears={8} totalYears={8}/>
                    <ProgressBar position={5} skill={'TypeScript'} numYears={3} totalYears={8}/>
                    <ProgressBar position={6} skill={'Node.js'} numYears={8} totalYears={8}/>
                    <ProgressBar position={7} skill={'Python'} numYears={6} totalYears={8}/>
                    <ProgressBar position={8} skill={'Django'} numYears={5} totalYears={8}/>
                    <ProgressBar position={9} skill={'GraphQL'} numYears={3} totalYears={8}/>
                    <ProgressBar position={10} skill={'SQL'} numYears={8} totalYears={8}/>
                </div>
            </div>
        </div>
    );
}

export default SkillsSection;
