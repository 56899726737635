import React, {useRef} from 'react';
import footerImage from '../images/footer-top.png';
import github from '../images/github.png';
import gmail from '../images/gmail.png';
import SectionTitle from "./SectionTitle";
import useOnScreen from "../hooks/useOnScreen";

interface FooterProps {
    // Define your prop types here (if any)
}

const Footer: React.FC<FooterProps> = (props) => {
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const ref4 = useRef<HTMLDivElement>(null);

    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);
    const isInView3 = useOnScreen(ref3);
    const isInView4 = useOnScreen(ref4);

    return (
        <div className="w-full">
            <div className="w-full">
                <img className="w-full h-[50px] md:h-[100px]" src={footerImage} />
            </div>
            <div className="bg-custom-gray py-20"> {/* Adjust padding as needed */}
                <div className="flex flex-row items-center w-full flex-wrap md:flex-nowrap">
                    <div className="flex flex-col items-center flex-grow mx-10 mb-24 md:mb-0">
                        <SectionTitle
                            title={'Contact me'} color={'white'}
                            animateDir={'right'}
                        />
                        <div
                            className="image-fade-in-up text-center font-open-sans text-lg font-normal text-white mt-4 py-5"
                            ref={ref1}
                        >
                            Have a question or want to work together?
                        </div>
                        <div
                            className="image-fade-in-up pb-10"
                            ref={ref2}
                        >
                            <a href="mailto:ashar.malik.m@gmail.com">
                                <img className="max-w-[72px]" src={gmail} />
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-center flex-grow mx-10">
                        <SectionTitle
                            title={'Check out GitHub'} color={'white'}
                            animateDir={'left'}
                        />
                        <div
                            className="image-fade-in-up text-center font-open-sans text-lg font-normal text-white mt-4 py-5"
                            ref={ref3}
                        >
                            Want to see some of my work in greater detail?
                        </div>
                        <div
                            className="image-fade-in-up pb-10"
                            ref={ref4}
                        >
                            <a href="https://github.com/asharmalik">
                                <img className="max-w-[72px]" src={github} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
