import image1 from "../images/Screenshot-1-Android.png";
import image2 from "../images/Screenshot-2-Android.png";
import image3 from "../images/Screenshot-3-Android.png";

import v1_1 from "../images/v1.0/Suggestions.png";
import v1_2 from "../images/v1.0/Invite Page.png";
import v1_3 from "../images/v1.0/Notifications.png";
import v1_4 from "../images/v1.0/Chat.png";

import v2_1 from "../images/v2.0/screenshot_1_framed.png";
import v2_2 from "../images/v2.0/screenshot_2_framed.png";
import v2_3 from "../images/v2.0/screenshot_3_framed.png";
import v2_4 from "../images/v2.0/screenshot_4_framed.png";

import v3_1 from "../images/v3.0/Screenshot 1.png"
import v3_2 from "../images/v3.0/Screenshot 2.png"
import v3_3 from "../images/v3.0/Screenshot 3.png"
import v3_4 from "../images/v3.0/Screenshot 4.png"
import v4_1 from "../images/v4.0/Screenshot 1.png"
import v4_2 from "../images/v4.0/Screenshot 2.png"
import v4_3 from "../images/v4.0/Screenshot 3.png"
import v4_4 from "../images/v4.0/Screenshot 4.png"
import v5_1 from "../images/v5.0/Screenshot 1.png"
import v5_2 from "../images/v5.0/Screenshot 2.png"
import v5_3 from "../images/v5.0/Screenshot 3.png"
import v5_4 from "../images/v5.0/Screenshot 4.png"
import v6_1 from "../images/v6.0/Screenshot 1.png"
import v6_2 from "../images/v6.0/Screenshot 2.png"
import React from "react";
import '../css/kickit-page.css';
import mixpanel from "mixpanel-browser";

interface KickitPageProps {
    // Define your prop types here (if any)
}

const KickitPage: React.FC<KickitPageProps> = (props) => {
    mixpanel.track('View Kickit Page');
    return (
        <div className="flex flex-wrap justify-center p-2 md:p-8">
            <div id="card1" className="card">
                <div className="card-title">Kickit Android App</div>
                <div className="screenshots">
                    <img src={image1}/>
                    <img src={image2}/>
                    <img src={image3}/>
                </div>
                <div className="card-description">
                    Kickit was my first serious journey into product development, mobile app development, and designing
                    scalable
                    architecture. By the last version, we had amassed almost 30,000 users.
                </div>
            </div>

            <div id="card2" className="card">
                <div className="card-title">Kickit v1.0</div>
                <div className="screenshots">
                    <img src={v1_1}/>
                    <img src={v1_2}/>
                    <img src={v1_3}/>
                    <img src={v1_4}/>
                </div>
                <div className="card-description">
                    This is the first iteration we launched to make it easier to hang out with friends. I used this
                    version to learn how to get familiar with making an Android app and the corresponding backend
                    infrastructure.
                </div>
            </div>

            <div id="card3" className="card">
                <div className="card-title">Kickit v2.0</div>
                <div className="screenshots">
                    <img src={v2_1}/>
                    <img src={v2_2}/>
                    <img src={v2_3}/>
                    <img src={v2_4}/>
                </div>
                <div className="card-description">
                    We took feedback from friends and mentors to create a fresh redesign. We focused on making the UI
                    look more appealing.
                </div>
            </div>

            <div id="card4" className="card">
                <div className="card-title">Kickit v3.0</div>
                <div className="screenshots">
                    <img src={v3_1}/>
                    <img src={v3_2}/>
                    <img src={v3_3}/>
                    <img src={v3_4}/>
                </div>
                <div className="card-description">
                    After more talking to users and gathering feedback, we realized the UX was too complicated. We tore
                    down the app and redesigned it from the ground up to focus on simplicity.
                </div>
            </div>

            <div id="card5" className="card">
                <div className="card-title">Kickit v4.0</div>
                <div className="screenshots">
                    <img src={v4_1}/>
                    <img src={v4_2}/>
                    <img src={v4_3}/>
                    <img src={v4_4}/>
                </div>
                <div className="card-description">
                    After enough testing, we realized making plans with friends was difficult to solve better than SMS
                    and group chats. So we pivoted to allowing students to meet other students.
                </div>
            </div>

            <div id="card6" className="card">
                <div className="card-title">Kickit v5.0</div>
                <div className="screenshots">
                    <img src={v5_1}/>
                    <img src={v5_2}/>
                    <img src={v5_3}/>
                    <img src={v5_4}/>
                </div>
                <div className="card-description">
                    With further testing, we came to a realization - there is far too much friction in attempting to get
                    strangers to meet up with short notice. However, from the usage we saw, people were utilizing Kickit
                    to communicate with others on campus instead of attempting to actually meet up. Another pivot was in
                    order; this time to allow local communication.
                </div>
            </div>

            <div id="card7" className="card">
                <div className="card-title">Kickit v6.0</div>
                <div className="screenshots">
                    <img src={v6_1}/>
                    <img src={v6_2}/>
                </div>
                <div className="card-description">
                    In this version, we tried a different approach. We switched to attempting to build a global
                    community rather than a local one. The hypothesis was it’s easier to build to a critical mass when
                    there aren’t arbitrary geographic barriers.
                </div>
            </div>
        </div>
    );
}

export default KickitPage;
